<template>
  <div class="p-success-payment">
    <div class="p-success-payment_top">
      <div class="p-success-payment_top_avatars">
        <div class="p-success-payment_top_avatars_circle_outer p-success-payment_top_avatars_circle_left">
          <div class="p-success-payment_top_avatars_circle_inner">
            <span>{{ partnerInitials }}</span>
          </div>
        </div>
        <Avatar
          :src="practitionerInfo && practitionerInfo.photoURL"
          class="p-success-payment_top_avatars_circle_inner p-success-payment_top_avatars_circle_right"
          size="52"
        />
      </div>
      <div class="p-success-payment_top_background"></div>
      <span v-if="!loading" class="p-success-payment_top_title">
        <img alt="ok" src="@/assets/icons/green-tick-rounded.svg" />
        <span class="heading-4">{{ $t("practitioner.appointments.success.title") }}</span>
      </span>
      <span v-else>
        <span class="d-flex">
          <v-progress-circular class="mr-2" color="primary" indeterminate size="22" width="2"></v-progress-circular>
          <span class="heading-4 mb-4">Pending Request</span>
        </span>
      </span>
      <span class="p-success-payment_top_text">{{ topText }}</span>
    </div>
    <div class="p-success-payment_middle">
      <v-row no-gutters>
        <PatientDetails :patient="patient" :practitioner="practitionerInfo" />
        <v-col class="p-success-payment_middle_right" cols="12">
          <v-row class="mb-3" no-gutters>
            <v-col col="1" md="2">
              <img alt="calendar" src="@/assets/icons/calendar-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="11" md="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.scheduledDate") }}:</span>
              <span>{{ visitStartDate }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col col="1" md="2">
              <img alt="time" src="@/assets/icons/time-blue.svg" />
            </v-col>
            <v-col class="p-success-payment_middle_right_column" cols="11" md="10">
              <span class="p-success-payment_middle_left_pre-text">{{ $t("visits.payment.visitDuration") }}:</span>
              <span>{{ `${visitStart} - ${visitEnd}` }} {{ gmtText }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="p-success-payment_bottom">
      <PrimaryButton
        :disabled="loading"
        :fullWidth="true"
        :text="$t('general.buttons.done')"
        size="large"
        @onClick="redirectToVisitCenter"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { timeZoneMixin } from "@/mixins/timeZone";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { stateTypes, usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

import PatientDetails from "./PatientDetails";

export default {
  name: "SuccessPaymentIndex",
  props: ["mode"],
  components: {
    PrimaryButton,
    Avatar,
    PatientDetails,
  },
  data() {
    return {
      loading: false,
      practitionerFullName: {
        firstName: "",
        lastName: "",
      },
    };
  },
  computed: {
    ...mapState(useAuthStore, ["role", "uid"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(usePatientVisitScheduleStore, ["practitionerInfo", "sessionId", "slot", "step"]),
    ...mapState(usePatientsStore, ["patients"]),
    topText() {
      return `Thank you for booking the visit with ${this.$t("general.titles.dr")} ${
        this.practitionerInfo?.firstName || ""
      } ${this.practitionerFullName?.lastName || ""}`;
    },
    partnerInitials() {
      return `${this.practitionerInfo?.firstName?.[0]?.toUpperCase() || ""} ${
        this.practitionerInfo?.lastName?.[0]?.toUpperCase() || ""
      }`;
    },
    visitStartDate() {
      return this.$moment(this.slot.start).tz(this.timeZone).format(timeDisplayFormat);
    },
    visitStart() {
      return this.$moment(this.slot.start).tz(this.timeZone).format("HH:mm A");
    },
    visitEnd() {
      return this.$moment(this.slot.end).tz(this.timeZone).format("HH:mm A");
    },
  },
  mixins: [timeZoneMixin],
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["cleanUp", "changeStep"]),
    ...mapActions(usePatientVisitScheduleStore, ["submitForm"]),
    async redirectToVisitCenter() {
      this.$emit("done");
      return await this.$router.push("/patient/visits/upcoming");
    },
  },
  async mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });

    try {
      this.loading = true;
      await this.submitForm();
      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
      if (err && err.response && err.response.status === 409) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("general.errors.slotConflict"),
          type: "error",
        });
      }
      snackBarEventBus.$emit(snackBarEventName, {
        message: err?.response?.data?.message || err?.response?.data?.message?.[0] || err,
        type: "error",
      });
    }
  },
  beforeDestroy() {
    if (this.step !== stateTypes.error) this.cleanUp();
  },
};
</script>

<style scoped></style>
