<template>
  <div class="wrap-slots">
    <div class="container-slots">
      <v-btn
        v-for="(slot, indexItem) in isExpanded ? slots : slots.slice(0, 12)"
        :key="indexItem"
        :class="{ 'fb-btn font-weight-bold': selectedSlotId === slot.id }"
        class="clockItem float-left textStyle"
        outlined
        @click="$emit('onSelect', slot.id)"
      >
        <span> {{ momentFun(slot.start) }} </span>
      </v-btn>
    </div>
    <p v-if="slots.length >= 15" class="see-all-btn" @click="isExpanded = !isExpanded">
      See {{ isExpanded ? "less" : "all" }}
    </p>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { useAuthStore } from "@/pinia-store/auth";

export default {
  props: ["slots", "selectedSlotId"],
  name: "SlotsOfDay",
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
  },
  methods: {
    momentFun(data) {
      return this.$moment.tz(data, this.timeZone).format("h:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-slots {
  .see-all-btn {
  }

  .container-slots {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    /*todo : may need to implement*/
    /*max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;*/
    button {
      padding: 12px;
      min-width: auto;
      margin-left: 0;
      margin-right: 0;
      letter-spacing: unset;
    }

    @media (max-width: 780px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 240px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
