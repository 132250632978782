<template>
  <div class="p-schedule-visit__content__visit-reason" style="padding: 20px 32px 32px 32px">
    <div>
      <span class="fontWeight500 heading-5 p-schedule-visit__content__visit-reason__title">
        {{ $t("visits.schedule.first.chiefComplaint") }}
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <div class="selectStyle marginTop16 marginBottom20">
        <Select
          :hideDetails="true"
          :items="chiefComplaints"
          :label="chiefComplaintLabel"
          :menuProps="{
            offsetY: true,
          }"
          :value="chiefComplaint"
          @change="onChiefComplaintChange"
        />
      </div>
      <span class="fontWeight500 heading-5 p-schedule-visit__content__visit-reason__title">
        {{ $t("visits.schedule.first.describeTheProblem") }}
        <span class="p-schedule-visit__content__required">*</span>
      </span>
      <div class="textareaStyle marginTop16 marginBottom20">
        <Textarea
          :hideDetails="true"
          :placeholder="$t('visits.visitReasonNote')"
          :value="this.complaintDescription"
          @change="onVisitReasonChange"
        />
      </div>
      <div class="textareaStyle marginTop16">
        <v-radio-group
          :hide-details="true"
          :value="virtualService"
          class="marginTop12 pt-0"
          @change="onVirtualServiceChange"
        >
          <v-radio label="Video visit" value="video"></v-radio>
          <v-radio label="Request a callback" value="callback"></v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Select from "@/components/uikit/Select";
import Textarea from "@/components/uikit/Textarea";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

export default {
  name: "ReasonForm",
  components: { Select, Textarea },
  computed: {
    ...mapState(usePatientVisitScheduleStore, ["complaintDescription", "chiefComplaint", "virtualService"]),
    chiefComplaintLabel() {
      return this.$t("visits.schedule.first.chooseYourComplaint");
    },
    chiefComplaints() {
      const chiefComplaints = this.$t("general.chiefComplaints");
      if (!chiefComplaints) {
        return [];
      }
      return Object.keys(chiefComplaints).map((key) => ({ text: chiefComplaints[key], value: key }));
    },
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["setStringFieldByName"]),
    onVisitReasonChange(value) {
      this.setStringFieldByName({ value, fieldName: "complaintDescription" });
    },
    onChiefComplaintChange(value) {
      this.setStringFieldByName({ value, fieldName: "chiefComplaint" });
    },
    onVirtualServiceChange(value) {
      this.setStringFieldByName({ value, fieldName: "virtualService" });
    },
  },
};
</script>

<style scoped></style>
