var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-schedule-visit"},[_c('Stepper',{attrs:{"isPrevStepRequired":true,"step":_vm.stepper,"stepChange":_vm.nextStep,"texts":_vm.steps}}),(_vm.stepper === 2)?_c('span',{staticClass:"heading-5 goBackButton",on:{"click":_vm.gotoStart}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-arrow-left")])],1)]):_vm._e(),_c('div',{staticClass:"p-schedule-visit__content"},[_c('v-card',{staticClass:"p-schedule-visit__content__card",staticStyle:{"width":"inherit"},attrs:{"max-width":"464px","outlined":""}},[(
          [
            _vm.stateTypes.patientPractitionerAvailable,
            _vm.stateTypes.showAllPatientPractitioner,
            _vm.stateTypes.showAllPractitioners,
          ].includes(_vm.step)
        )?_c('FirstStep'):_vm._e(),(_vm.step === _vm.stateTypes.medicalInfo)?_c('SecondStep',{attrs:{"mode":_vm.mode},on:{"slotConflict":_vm.slotConflict}}):_vm._e(),(_vm.step === _vm.stateTypes.checkout)?_c('SuccessPayment',{attrs:{"step":_vm.nextStep}}):_vm._e(),(_vm.step === _vm.stateTypes.error)?_c('ExpireError',{attrs:{"errorType":_vm.errorType}}):_vm._e()],1)],1),_c('Snackbar',{attrs:{"text":_vm.snackbar.text,"timeout":5000,"type":_vm.snackbar.type},model:{value:(_vm.snackbar.value),callback:function ($$v) {_vm.$set(_vm.snackbar, "value", $$v)},expression:"snackbar.value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }