<template>
  <div>
    <div class="backgroundColorBlue visitDetails p-schedule-visit__content__visit-reason text-center">
      <v-list-item-avatar style="margin: auto !important">
        <Avatar :src="patient.practitioner && patient.practitioner.photoURL" />
      </v-list-item-avatar>
      <div class="fontSize20 textStyle fontWeight500" style="margin-top: 10px">{{ this.doctorName }}</div>
      <div class="fontSize14 lightGray fontWeight500" style="margin-top: 9px">
        {{ (patient.practitioner && patient.practitioner.qualification) || "Clinician" }}
      </div>
    </div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 30px 32px 32px 32px">
      <div class="fontWeight500 textStyle fontSize16 colorBlack">
        {{ $t("visits.schedule.first.yourNextAvailable") }}
      </div>
      <div style="margin-top: 9px">
        <div class="d-flex justify-center dateContainer">
          <v-col class="heading-5 p-schedule-visit__content__calendar__title pa-0">
            <div class="align-start d-flex">
              <div>
                <img
                  alt="Calendar"
                  class="p-schedule-visit__content__icon marginTop2"
                  src="../../../assets/icons/calendar-blue.svg"
                />
              </div>
              <div>
                <span class="fontSize14 lightGray paddingLeft5 mb-3">
                  {{ $t("visits.schedule.first.date") }}
                </span>
                <div class="col-12 pa-0 dateStyle2 ma-0 mt-3">
                  <DatePicker
                    :custom-dates="practitionerDatesOfSlots(patient.practitioner && patient.practitioner.id)"
                    :dense="true"
                    :height="47"
                    :hide-details="true"
                    :hideTitle="true"
                    :isYearFirst="false"
                    :maxDate="maxScheduleDate"
                    :minDate="minScheduleDate"
                    :value="$searchDate"
                    className="calendar_header_date-picker v-input--hide-details2 calendarStyle ml-0"
                    color="primary"
                    @setDate="onChangeSearchDate"
                  />
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="heading-5 col-6 pa-0 timeContainer">
            <div class="align-center d-flex">
              <img alt="Time" class="p-schedule-visit__content__icon" src="../../../assets/icons/time-blue.svg" />
              <span class="lightGray fontSize14 paddingLeft5">{{ $t("visits.schedule.first.time") }}</span>
            </div>
            <div v-click-outside="onClickOutside" class="position-relative mt-3">
              <div class="m-auto col-sm-12 col-md-11 pa-0">
                <v-btn
                  class="buttonStyle fontSize14 fontWeight500 pa-0"
                  large
                  style="margin-left: 8px"
                  @click="toggleIsOpenedTimeSlots"
                >
                  <span v-if="slotId && slot && slot.id">{{ momentFun(slot.start) }}</span>
                  <span v-else style="font-size: 8px">No Select</span>
                </v-btn>
                <div v-if="isOpenedTimeSlots" class="modalSelectButton position-absolute">
                  <div class="d-flex col-12 pa-0 justify-space-between align-center">
                    <v-icon class="position-relative iconLeft colorBlack fontSize16" @click="toPreviousDate">
                      mdi-chevron-left
                    </v-icon>
                    {{ $moment($searchDate).format("dddd, MMMM DD, YYYY") }}
                    <v-icon class="colorBlack position-relative iconRight fontSize16" @click="toNextDate">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                  <div v-if="slotsOnSearchDate.length" class="wrap-clock-items">
                    <div v-for="(item, ind) in slotsOnSearchDate" :key="ind">
                      <v-btn
                        :class="{
                          'fb-btn font-weight-bold': slotId === item.id,
                        }"
                        class="clockItemModal clockItem fontSize14"
                        outlined
                        v-on:click="onSelectSlot(item.id)"
                      >
                        <span>{{ momentFun(item.start) }}</span>
                      </v-btn>
                    </div>
                  </div>
                  <div v-else class="unavailable mx-auto">Unavailable</div>
                  <v-btn
                    :style="[slotsOnSearchDate.length ? { 'margin-top': '27px' } : { 'margin-top': '41px' }]"
                    block
                    class="viewFullSchedule"
                    elevation="2"
                    outlined
                    tile
                    @click="onShowAllPatientPractitioner"
                    >View full schedule
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </div>
      <v-col class="col-12" cols="12">
        <v-progress-linear v-if="this.isLoading" color="primary" indeterminate></v-progress-linear>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import DatePicker from "@/components/shared/DatePicker";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { stateTypes, usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

export default {
  name: "PatientPractitionerAvailable",
  components: { DatePicker, Avatar },
  data: () => ({
    isOpenedTimeSlots: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePatientStore, ["patientsPractitioner", "patient"]),
    ...mapState(usePatientVisitScheduleStore, [
      "practitionerDatesOfSlots",
      "slot",
      "$searchDate",
      "slotsOnSearchDate",
      "$practitionersWithSlots",
      "maxScheduleDate",
      "minScheduleDate",
      "slotId",
      "isLoading",
    ]),
    doctorName() {
      return (this.patient.practitioner?.firstName || "") + " " + (this.patient.practitioner?.lastName || "");
    },
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["changeStep", "setStringFieldByName", "setSlotId"]),
    ...mapActions(usePatientVisitScheduleStore, ["selectFistSlotFromSearchDate"]),

    onClickOutside() {
      this.isOpenedTimeSlots = false;
    },
    momentFun(data) {
      return this.$moment(data).tz(this.timeZone).format("HH:mm A");
    },
    toggleIsOpenedTimeSlots() {
      this.isOpenedTimeSlots = !this.isOpenedTimeSlots;
    },
    onChangeSearchDate(value) {
      this.setStringFieldByName({ fieldName: "searchDate", value: this.$moment(value).format("YYYY-MM-DD") });
      this.selectFistSlotFromSearchDate();
    },
    toPreviousDate() {
      if (this.$searchDate === this.minScheduleDate) return;

      const dates = this.practitionerDatesOfSlots(this.patient.practitioner.id);
      let ind = dates.findIndex((i) => i === this.$searchDate);
      if (ind > 0) {
        this.setStringFieldByName({
          fieldName: "searchDate",
          value: dates[ind - 1],
        });
        this.selectFistSlotFromSearchDate();
      }
    },
    toNextDate() {
      const dates = this.practitionerDatesOfSlots(this.patient.practitioner.id);
      let ind = dates.findIndex((i) => i === this.$searchDate);
      if (ind < dates.length - 2) {
        this.setStringFieldByName({
          fieldName: "searchDate",
          value: dates[ind + 1],
        });
        this.selectFistSlotFromSearchDate();
      }
    },
    onSelectSlot(slotId) {
      this.setSlotId({ slotId: slotId, timeZone: this.timeZone });
    },
    onShowAllPatientPractitioner() {
      this.changeStep(stateTypes.showAllPatientPractitioner);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-clock-items {
  max-width: 100%;
  grid-template-rows: 1fr 1fr;
  justify-content: start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
