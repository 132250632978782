<template>
  <PatientScheduleVisit />
</template>
<script>
import PatientScheduleVisit from "@/components/Checkout/PatientScheduleVisit";
export default {
  name: "PatientRequestVisitView",
  components: {
    PatientScheduleVisit,
  },
};
</script>
