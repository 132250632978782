<template>
  <div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 21px 32px 21px 32px">
      <div class="what-time-works">What time works for you?</div>
    </div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 20px 32px 32px 32px">
      <div class="align-center d-flex">
        <img alt="Calendar" class="p-schedule-visit__content__icon" src="../../../assets/icons/calendar-blue.svg" />
        <span class="textStyle fontSize16 fontWeight500 colorBlack">{{
          $t("visits.schedule.first.startSearchOn")
        }}</span>
      </div>
      <div class="col-12 pa-0 dateStyle" style="margin-top: 16px">
        <DatePicker
          :dense="true"
          :height="47"
          :hide-details="true"
          :hideTitle="true"
          :isYearFirst="false"
          :maxDate="this.maxScheduleDate"
          :minDate="this.minScheduleDate"
          :value="filterDaysDate"
          class="col-12 fontWeight500 textStyle"
          className="calendar_header_date-picker v-input--hide-details2 ml-0"
          color="primary"
          @setDate="onChangeSearchDate"
        />
      </div>
    </div>
    <div>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, ind) in $practitionersWithSlots"
          :key="ind"
          class="availability-panel"
          v-bind:style="[ind === 0 && { background: '#f8fcfe' }]"
          @change="expandAvailablePractitioner(item)"
        >
          <v-expansion-panel-header class="pa-0">
            <div
              :style="[isPractitionerOfPatient(item.practitioner) && { background: '#f8fcfe' }]"
              class="p-schedule-visit__content__visit-reason"
              style="padding: 16px"
            >
              <div
                v-if="isPractitionerOfPatient(item.practitioner)"
                class="fontWeight500 fontSize16 textStyle colorBlack"
              >
                {{ $t("visits.schedule.first.doctorYouSeeBefore") }}
              </div>
              <div class="d-flex justify-center align-center">
                <v-col class="col-2 pa-0" style="margin-right: -5px">
                  <v-list-item-avatar>
                    <Avatar :src="item.practitioner && item.practitioner.photoURL" />
                  </v-list-item-avatar>
                </v-col>
                <v-col class="col-10 pa-0">
                  <div class="fontWeight500 fontSize16 textStyle colorBlack">
                    Dr. {{ item.practitioner && item.practitioner.firstName }}
                    {{ item.practitioner && item.practitioner.lastName }}
                  </div>
                  <div class="fontSize14 colorBlack fontWeight500" style="opacity: 0.4">
                    {{ (item.practitioner && item.practitioner.qualification) || "Clinician" }}
                  </div>
                  <span v-if="item.practitioner.isTestingUser" class="pt-1 d-flex align-center text-capitalize">
                    <v-icon color="red" small>mdi-information-outline</v-icon>
                    &nbsp;Testing account
                  </span>
                </v-col>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="first-available-panel pa-0">
            <div class="d-flex justify-center">
              <v-progress-circular v-if="item.isLoading" color="primary" indeterminate></v-progress-circular>
            </div>
            <template v-if="!item.isLoading">
              <DaysOfSlots
                v-if="item && item.slots && item.slots.length"
                :practitioner-id="item.practitioner && item.practitioner.id"
                :start-date="filterDaysDate"
              />
              <div v-else class="unavailable ma-1 mx-auto">Unavailable</div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import DaysOfSlots from "@/components/Checkout/PatientScheduleVisit/DaysOfSlots";
import DatePicker from "@/components/shared/DatePicker";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

export default {
  name: "AllPractitioners",
  components: { DaysOfSlots, Avatar, DatePicker },
  data() {
    return {
      perPage: 3,
      scrollToDay: this.$moment(this.filterDaysDate).add(this.$moment(this.perPage)).days(),
    };
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(usePatientVisitScheduleStore, [
      "$practitionersWithSlots",
      "filterDaysDate",
      "maxScheduleDate",
      "slotId",
      "minScheduleDate",
      "practitionerSlotsOfDate",
    ]),
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["setSlotId", "setStringFieldByName", "setPractitionersWithSlots"]),
    ...mapActions(usePatientVisitScheduleStore, ["getPractitionerSlots", "preloadPractitioners"]),
    isPractitionerOfPatient(practitioner) {
      return this.patient?.practitioner?.id === practitioner.id;
    },
    onChangeSearchDate(value) {
      this.setStringFieldByName({ fieldName: "filterDaysDate", value: this.$moment(value).format("YYYY-MM-DD") });
    },

    async expandAvailablePractitioner(item) {
      let newLoad = { ...item };
      const existIndex = this.$practitionersWithSlots.findIndex((i) => i?.practitioner?.id === item.practitioner.id);
      let newIns = [...this.$practitionersWithSlots];

      if (item.isLoading) {
        newIns[existIndex] = { ...newLoad, expanded: !item.expanded };
        this.setPractitionersWithSlots(this.$practitionersWithSlots);
        return;
      }

      if (!item.loaded) {
        newIns[existIndex] = { ...newLoad, isLoading: true, expanded: true };
        this.setPractitionersWithSlots(newIns);
        await this.getPractitionerSlots({ practitionerId: item.practitioner.id, expanded: true });
        return;
      }
      this.practitionersWithSlots[existIndex] = { ...newLoad, expanded: !item.expanded };
      this.setPractitionersWithSlots(this.$practitionersWithSlots);
    },
  },
  async mounted() {
    await this.preloadPractitioners();
  },
};
</script>

<style scoped></style>
