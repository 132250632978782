<template>
  <div>
    <div>
      <div class="p-schedule-visit__content__title relative">
        <div v-if="!isLayoutPatientPractitionerAvailable" class="heading-5 goBackButton absolute" @click="onGoBack">
          <div class="d-flex" style="align-items: center">
            <v-icon class="mr-1">mdi-arrow-left</v-icon>
          </div>
        </div>
        <span class="heading-4">{{ title }}</span>
      </div>
      <VisitOwner />
      <div>
        <PatientPractitionerAvailable v-if="this.isLayoutPatientPractitionerAvailable" />
        <AllPatientPractitioner v-if="this.isLayoutAllPatientPractitioner" />
        <AllPractitioners v-if="this.isLayoutAllPractitioners" />
        <div>
          <ReasonForm />
          <div>
            <span class="fontWeight500 heading-5 px-8 py-4 d-block"> Attachments </span>
            <FileRow
              v-for="file in files"
              :editable="false"
              :key="file.id"
              :hide-delete="!fileCanBeDeleted(file)"
              :hide-rename="true"
              :file="file"
            />
            <MediaUploader @onUploaded="onFileLoaded" />
          </div>
          <div class="p-schedule-visit__content__visit-reason" style="padding: 32px">
            <div>
              <PrimaryButton
                :disabled="!this.isAcceptButtonEnabled"
                :fullWidth="true"
                :loading="buttonLoading"
                :text="submitButtonText"
                className="fontWeight500 fontSize16"
                size="large"
                @onClick="onSubmit(false)"
              />
            </div>
          </div>
          <div v-if="isLayoutPatientPractitionerAvailable" style="padding: 22px 32px 20px 32px">
            <div class="buttonContainer">
              <v-btn
                block
                class="fontSize16 seeAllButton"
                elevation="2"
                outlined
                x-large
                @click="gotoAllPatientPractitioner"
                >{{ $t("visits.schedule.first.seeAllSlots") }} {{ this.doctorName }}
              </v-btn>
            </div>
          </div>
          <div
            v-if="isLayoutPatientPractitionerAvailable"
            class="p-schedule-visit__content__visit-reason"
            style="padding: 0 32px 22px 32px"
          >
            <div class="buttonContainer">
              <v-btn block class="fontSize16 seeAllButton" elevation="2" outlined x-large @click="gotoAllPractitioners"
                >{{ $t("visits.schedule.first.firstAvailableDoctor") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import AllPatientPractitioner from "@/components/Checkout/PatientScheduleVisit/AllPatientPractitioner";
import AllPractitioners from "@/components/Checkout/PatientScheduleVisit/AllPractitioners";
import PatientPractitionerAvailable from "@/components/Checkout/PatientScheduleVisit/PatientPractitionerAvailable";
import ReasonForm from "@/components/Checkout/PatientScheduleVisit/ReasonForm";
import VisitOwner from "@/components/Checkout/PatientScheduleVisit/VisitOwner.vue";
import FileRow from "@/components/media/FileRow.vue";
import MediaUploader from "@/components/media/MediaUploader.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAppointmentsStore } from "@/pinia-store/appointments";
import { useAuthStore } from "@/pinia-store/auth";
import { useMediaStore } from "@/pinia-store/media";
import { useOwnersStore } from "@/pinia-store/owners";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientsStore } from "@/pinia-store/patients";
import { stateTypes, usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { useSlotsStore } from "@/pinia-store/slots";

export default {
  name: "FirstStepV2",
  props: {
    mode: String,
    buttonLoading: { default: false, type: Boolean },
  },

  data: () => ({
    currentDate: new Date(),
    dataClock: [],
    closeTheVisit: false,
    showAll: false,
    showFirstAvailable: false,
    isActive: "all",
    genderValue: "",
    numberValue: "",
    selectOptions: [],
    loading: true,
    practitionerList: [],
    doctorHasSlot: false,
    doctorName: "",
    clinicianSearch: false,
    timeValue: "",
    isActiveButton: "",
    isFreeTime: "",
    startDate: "",
    severalTimes: 0,
    searchPractitioner: [],
    searchLoading: false,
    selectClock: "",
    selectTime: false,
    selectedDateSlots: [],
  }),
  components: {
    FileRow,
    MediaUploader,
    VisitOwner,
    AllPractitioners,
    AllPatientPractitioner,
    PatientPractitionerAvailable,
    ReasonForm,
    PrimaryButton,
  },
  computed: {
    ...mapState(useAuthStore, ["uid", "timeZone"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(usePatientVisitScheduleStore, [
      "slot",
      "$searchDate",
      "files",
      "isLoading",
      "isLayoutPatientPractitionerAvailable",
      "isLayoutAllPatientPractitioner",
      "isLayoutAllPractitioners",
      "isAcceptButtonEnabled",
      "sessionId",
      "virtualService",
    ]),
    ...mapState(useSlotsStore, ["slotsArray"]),
    ...mapState(useAppointmentsStore, [
      "slotId",
      "complaintDescription",
      "chiefComplaint",
      "visitDate",
      "patientId",
      "practitionerId",
    ]),
    ...mapState(usePatientsStore, ["practitionerOwnPatients", "practitioners"]),
    ...mapState(usePatientStore, ["patient"]),

    isButtonDisabled() {
      return !this.slotId || !this.complaintDescription || !this.chiefComplaint || !this.currentDate;
    },

    title() {
      return this.$t("visits.schedule.first.title");
    },
    submitButtonText() {
      return this.sessionId ? "Accept time" : this.$t("general.sidebar.scheduleVisit");
    },
  },
  methods: {
    ...mapActions(useAppointmentsStore, ["setStringFieldByName"]),
    ...mapActions(usePatientsStore, ["getAllPractitioners"]),
    ...mapActions(useOwnersStore, ["chosePractitioner"]),
    ...mapActions(useAppointmentsStore, ["clearAllFields"]),
    ...mapActions(usePatientVisitScheduleStore, [
      "preloadPractitioners",
      "getPractitionerSlots",
      "selectFistAvailableSlotFromPatientPractitioner",
      "setSlotOnHold",
    ]),
    ...mapActions(usePatientVisitScheduleStore, { setVisitSearchField: "setStringFieldByName" }),
    ...mapActions(usePatientVisitScheduleStore, ["changeStep", "setFiles", "deleteFile"]),
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(useSlotsStore, ["getPractitionerFreeSlots", "getSlotById"]),
    ...mapActions(useMediaStore, ["setUid"]),
    fileCanBeDeleted(file) {
      return file.creatorUid === this.uid;
    },
    onFileRemove(removedFile) {
      this.deleteFile(removedFile.id);
    },
    onFileLoaded(files) {
      this.setFiles(files);
    },
    gotoAllPatientPractitioner() {
      this.changeStep(stateTypes.showAllPatientPractitioner);
    },
    onGoBack() {
      this.selectFistAvailableSlotFromPatientPractitioner();
      this.changeStep(stateTypes.patientPractitionerAvailable);
    },
    async gotoAllPractitioners() {
      await this.setVisitSearchField({ fieldName: "searchDate", value: this.$moment().format("YYYY-MM-DD") });
      this.changeStep(stateTypes.showAllPractitioners);
    },

    async onSubmit() {
      this.changeStep(stateTypes.medicalInfo);
      await this.setSlotOnHold(this.slot.id);
    },
  },
  created() {
    this.clearAllFields();
  },
  async mounted() {
    this.loading = true;
    this.severalTimes = 1;
    window.scrollTo({ top: 0, behavior: "auto" });
    this.loading = false;
  },
};
</script>
<style lang="scss">
.availability-panel {
  box-shadow: none;

  &::before {
    box-shadow: none;
  }

  .see-all-btn {
    color: $primary;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
  }
}

.v-expansion-panel-content.first-available-panel {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

@import "firstStep.style";
.marginTop16 {
  margin-top: 16px;
}

.marginBottom20 {
  margin-bottom: 20px;
}
</style>
