<template>
  <div class="days-of-slots-wrap">
    <div v-for="(date, ind) in scrollDays" :key="ind" class="availability-panel">
      <div v-if="practitionerSlotsOfDate(practitionerId, date).length">
        <div class="p-schedule-visit__content__visit-reason pa-0">
          <div :key="ind" class="clockItemContainer">
            <span class="fontWeight500 heading-5 p-schedule-visit__content__time__title">
              <img alt="Time" class="p-schedule-visit__content__icon" src="../../../assets/icons/time-blue.svg" />
              {{ dateBool(date) }}
            </span>
            <SlotsOfDay
              :selectedSlotId="slotId"
              :slots="practitionerSlotsOfDate(practitionerId, date)"
              @onSelect="onSelectSlot"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-schedule-visit__content__visit-reason">
      <v-btn
        :disabled="!showMorePages"
        block
        class="fontWeight500 fontSize16 moreButton"
        elevation="2"
        outlined
        x-large
        @click="onNextDays"
        >See More
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import SlotsOfDay from "@/components/Checkout/PatientScheduleVisit/SlotsOfDay";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

export default {
  props: ["practitionerId", "startDate"],
  name: "DaysOfSlots",
  components: { SlotsOfDay },
  data() {
    return {
      presentedCount: 3,
      perPage: 3,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(usePatientVisitScheduleStore, [
      "practitionerDatesOfSlots",
      "maxScheduleDate",
      "slotId",
      "minScheduleDate",
      "$searchDate",
      "filterDaysDate",
      "practitionerSlotsOfDate",
      "practitionerDatesOfSlots",
    ]),
    totalDates() {
      return this.practitionerDatesOfSlots(this.practitionerId).filter((date) =>
        this.$moment(this.startDate).isSameOrBefore(date),
      ).length;
    },
    scrollDays() {
      const dates = this.practitionerDatesOfSlots(this.practitionerId).filter((date) =>
        this.$moment(this.startDate).isSameOrBefore(date),
      );
      let arr = [];
      for (let i = 0; i < this.presentedCount; i++) arr.push(this.$moment(dates[i]).format("YYYY-MM-DD"));
      return arr;
    },
    showMorePages() {
      return this.presentedCount + this.perPage > this.totalDates
        ? parseInt(this.presentedCount + this.perPage)
        : this.totalDates;
    },
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["setSlotId", "setStringFieldByName"]),
    dateBool(date) {
      return this.$moment(date).format(`dddd, MMMM DD, YYYY `);
    },
    onSelectSlot(slotId) {
      this.setSlotId({ slotId: slotId, timeZone: this.timeZone });
    },
    onNextDays() {
      if (parseInt(this.presentedCount + this.perPage) < this.totalDates)
        this.presentedCount = parseInt(this.presentedCount + this.perPage);
      else this.presentedCount = this.totalDates;
    },
  },
};
</script>

<style lang="scss" scoped>
.days-of-slots-wrap {
  .clockItemContainer {
    border-bottom: 1px solid #e7e8f2;

    > span {
      margin-bottom: 4px;
    }
  }

  .p-schedule-visit__content__visit-reason {
    padding: 20px 32px 20px 32px;
  }
}
</style>
