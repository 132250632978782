<template>
  <div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 21px 32px 21px 32px">
      <div class="textStyle fontSize16 colorBlack fontWeight500">
        Your next available appointment with {{ this.doctorName }}
      </div>
      <div class="what-time-works">What time works for you?</div>
    </div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 16px 32px 15px 32px">
      <div class="d-flex justify-center align-center">
        <v-col class="col-2" style="padding: 0; margin-right: -10px">
          <v-list-item-avatar>
            <Avatar :src="patient.practitioner && patient.practitioner.photoURL" class="" />
          </v-list-item-avatar>
        </v-col>
        <v-col class="col-10 p-0" style="padding: 0">
          <div class="textStyle fontSize16 colorBlack fontWeight500">{{ this.doctorName }}</div>
          <div class="fontSize14 fontWeight500" style="opacity: 0.4">
            {{ (patient.practitioner && patient.practitioner.qualification) || "Clinician" }}
          </div>
        </v-col>
      </div>
    </div>
    <div class="p-schedule-visit__content__visit-reason" style="padding: 20px 32px 32px 32px">
      <div class="align-center d-flex">
        <img class="p-schedule-visit__content__icon" src="../../../assets/icons/calendar-blue.svg" />
        <span class="textStyle fontSize16 fontWeight500 colorBlack">{{
          $t("visits.schedule.first.startSearchOn")
        }}</span>
      </div>
      <div class="col-12 pa-0 dateStyle" style="margin-top: 16px">
        <DatePicker
          :custom-dates="practitionerDatesOfSlots(patient.practitioner.id)"
          :dense="true"
          :height="47"
          :hide-details="true"
          :hideTitle="true"
          :isYearFirst="false"
          :maxDate="this.maxScheduleDate"
          :minDate="this.minScheduleDate"
          :value="filterDaysDate"
          class="col-12 fontWeight500 textStyle"
          className="calendar_header_date-picker v-input--hide-details2 ml-0"
          color="primary"
          @setDate="onChangeFilterDaysDate"
        />
      </div>
    </div>
    <div>
      <AllPractitionersItem :practitioner-id="patient.practitioner.id" :start-date="filterDaysDate" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import Avatar from "@/components/Avatar/Index";
import AllPractitionersItem from "@/components/Checkout/PatientScheduleVisit/DaysOfSlots";
import DatePicker from "@/components/shared/DatePicker";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";

export default {
  name: "AllPatientPractitioner",
  components: { AllPractitionersItem, Avatar, DatePicker },
  data() {
    return {
      perPage: 3,
      scrollToDay: this.$moment(this.filterDaysDate).add(this.$moment(this.perPage)).days(),
    };
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePatientStore, ["patient"]),
    ...mapState(usePatientVisitScheduleStore, [
      "practitionerDatesOfSlots",
      "maxScheduleDate",
      "slotId",
      "minScheduleDate",
      "$searchDate",
      "filterDaysDate",
      "practitionerSlotsOfDate",
    ]),
    doctorName() {
      return (this.patient.practitioner?.firstName || "") + " " + (this.patient.practitioner?.lastName || "");
    },
  },
  methods: {
    ...mapActions(usePatientVisitScheduleStore, ["setSlotId", "setStringFieldByName"]),
    onChangeFilterDaysDate(value) {
      this.setStringFieldByName({ fieldName: "filterDaysDate", value: this.$moment(value).format("YYYY-MM-DD") });
    },
  },
};
</script>

<style scoped></style>
