<template>
  <div class="p-schedule-visit">
    <Stepper :isPrevStepRequired="true" :step="stepper" :stepChange="nextStep" :texts="steps" />
    <span v-if="stepper === 2" class="heading-5 goBackButton" @click="gotoStart">
      <div class="d-flex" style="align-items: center">
        <v-icon class="mr-1">mdi-arrow-left</v-icon>
      </div>
    </span>
    <div class="p-schedule-visit__content">
      <v-card class="p-schedule-visit__content__card" max-width="464px" outlined style="width: inherit">
        <FirstStep
          v-if="
            [
              stateTypes.patientPractitionerAvailable,
              stateTypes.showAllPatientPractitioner,
              stateTypes.showAllPractitioners,
            ].includes(step)
          "
        />
        <SecondStep v-if="step === stateTypes.medicalInfo" :mode="mode" @slotConflict="slotConflict" />
        <SuccessPayment v-if="step === stateTypes.checkout" :step="nextStep" />
        <ExpireError v-if="step === stateTypes.error" :errorType="errorType" />
      </v-card>
    </div>
    <Snackbar v-model="snackbar.value" :text="snackbar.text" :timeout="5000" :type="snackbar.type" />
  </div>
</template>
<script>
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import ExpireError from "@/components/Checkout/PatientScheduleVisit/ExpireError";
import FirstStep from "@/components/Checkout/PatientScheduleVisit/FirstStep";
import SecondStep from "@/components/Checkout/PatientScheduleVisit/SecondStep";
import SuccessPayment from "@/components/Checkout/PatientScheduleVisit/SuccessPayement/Index";
import Snackbar from "@/components/shared/Snackbar";
import Stepper from "@/components/shared/Stepper";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { WS_SLOTS_ON_HOLD_UPDATE } from "@/eventBuses/socketEvents";
import { useAuthStore } from "@/pinia-store/auth";
import { usePatientStore } from "@/pinia-store/patient";
import { stateTypes, usePatientVisitScheduleStore } from "@/pinia-store/patientVisitSchedule";
import { useSlotsStore } from "@/pinia-store/slots";
import { useVisitSettingsStore } from "@/pinia-store/visitSettings";

export default {
  name: "PatientRequestVisit",
  components: {
    FirstStep,
    Stepper,
    SecondStep,
    Snackbar,
    SuccessPayment,
    ExpireError,
  },
  data: () => ({
    stateTypes,
    mode: "create",
    loading: false,
    buttonLoading: false,
    errorType: "",
    snackbar: {
      value: false,
      text: "",
      type: "success",
    },
    practitionerPrice: null,
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapState(usePatientStore, ["patientsPractitioner", "patient"]),
    ...mapState(usePatientVisitScheduleStore, ["step", "slot"]),
    stepper() {
      if (
        [
          stateTypes.patientPractitionerAvailable,
          stateTypes.showAllPatientPractitioner,
          stateTypes.showAllPractitioners,
        ].includes(this.step)
      )
        return 1;
      if (stateTypes.medicalInfo) return 2;
      return 3;
    },
    steps() {
      const steps = this.$i18n.t("visits.schedule.steps");
      steps._3 = this.$i18n.t("visits.schedule.practitionerSteps")._2;
      return steps;
    },
  },
  methods: {
    ...mapActions(usePatientStore, ["getPatientProfile"]),
    ...mapActions(usePatientVisitScheduleStore, [
      "setPatientId",
      "setSlotsOnHold",
      "changeStep",
      "setStringFieldByName",
      "setSessionId",
      "cleanUp",
      "setSlotId",
      "setPractitionerId",
    ]),
    ...mapActions(useSlotsStore, ["getPractitionerFreeSlots", "getMyDoctorFreeSlots", "getSlotById"]),
    ...mapActions(useVisitSettingsStore, ["preloadVisitSettings"]),
    ...mapActions(usePatientVisitScheduleStore, [
      "getSlotsOnHold",
      "preloadPractitioners",
      "getSessionInfo",
      "getPractitionerSlots",
      "selectFistAvailableSlotFromPatientPractitioner",
    ]),
    nextStep(nextStep) {
      this.step = nextStep;
    },
    gotoStart() {
      this.changeStep(stateTypes.patientPractitionerAvailable);
    },
    async slotConflict() {
      this.step = 1;
      await this.getPractitionerFreeSlots({ status: "free" });
    },
    async load() {
      const { sessionId } = this.$route.params;
      await Promise.all([this.preloadVisitSettings(), this.getPatientProfile()]);
      this.setStringFieldByName({ fieldName: "isPatientProfileLoaded", value: true });

      try {
        this.loading = true;
        if (sessionId) {
          this.setSessionId(sessionId);
          const appointment = await this.getSessionInfo();
          if ((appointment.status && appointment.status === "cancelled") || !appointment.slotId) {
            this.errorType = "expired";
            this.changeStep(stateTypes.error);
            return;
          }
          const date = DateTime.fromISO(this.slot?.end).toJSDate();
          const reducedDate = new Date(date.valueOf() - 3 * 60000);
          if (reducedDate < new Date() || moment().isAfter(this.slot.end)) {
            this.errorType = "expired";
            this.changeStep(stateTypes.error);
            return;
          }
          if (this.status === "booked") {
            this.errorType = "completed";
            this.changeStep(stateTypes.error);
            return;
          }
          if (appointment.status && appointment.status === "success") {
            this.changeStep(stateTypes.error);
          }
        } else {
          if (this.patient.practitioner?.id) {
            this.setPractitionerId(this.patient.practitioner.id);
            await this.getPractitionerSlots({ practitionerId: this.patient.practitioner.id });
            this.selectFistAvailableSlotFromPatientPractitioner();
          }
        }
        await this.getSlotsOnHold();
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: err?.response?.data?.message || err,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    this.cleanUp();
    this.sockets.unsubscribe(WS_SLOTS_ON_HOLD_UPDATE);
  },
  async mounted() {
    await this.load();
    this.setPatientId(this.uid);
    this.sockets.subscribe(WS_SLOTS_ON_HOLD_UPDATE, (data) => {
      this.setSlotsOnHold({ slots: data, patientId: this.uid });
    });
  },
  watch: {
    async $route() {
      await this.load();
    },
  },
};
</script>
<style lang="scss" scoped>
.goBackButton {
  margin-left: 50px;
  display: inline-block;
  margin-top: 30px;
  cursor: pointer;
  align-items: center;
}
</style>
